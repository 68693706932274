import settings from '../data/settings.json';

const regexHtml = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,"gm")
const regexSelf = new RegExp(`${settings.domain}`, 'i');

const isValidUrl = urlString => {
  let urlPattern = new RegExp(/(?:http(s)?:\/\/)?([\w\-])+\.{1}([a-zA-Z]{2,63})([\/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)/,'g');
  return !!urlPattern.test(urlString);
}
const matchingUrl = (url, regex) => {
  if (!url) return null;
  const match = url.match(regex);
  return match ? match[0] : null;
}

const validateUrl = (url) => {
  return isValidUrl(url);
}
export default validateUrl;

export const shortcodeUrl = (url) => {
  let mUrl = matchingUrl(url, regexHtml);
  if (!regexSelf.test(mUrl)) return null;
  return mUrl.replace('https://','').split('/')[1];
}

export const makeUrl = (url) => {
  return !!url.match(/http(s)?:\/\//gi) ? url : `http://${url}`
}
