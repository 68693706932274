import { Container, Paper, Grid, Typography } from '@material-ui/core';
import Converter from '../components/Converter'
import { header, converter, features, footer } from '../data/strings.json'
import Header from '../components/Header'
import Footer from '../components/Footer'

import '../styles/App.css';

function App() {
  const itemCell = (item) => (
    <Grid item xs={4} style={{ margin: '0 0 16px 0', padding: 4 }}>
      <Paper style={{minHeight: '100%', background: 'rgba(255,255,255,0.77)'}}>
        <Typography variant="h6">
          {item.name}
        </Typography>
        <Typography variant="body1">
          {item.description}
        </Typography>
      </Paper>
    </Grid>
  )

  return (
    <div className="App">
      <Container maxWidth="sm">
        <Header {...header} />
        <Converter strings={converter} />
        <div style={{ display:'flex', flexWrap: 'wrap', alignItems: 'stretch', marginBottom: '3rem' }}>
          { features.map((item) => itemCell(item)) }
        </div>
        <br/>
        <Footer {...footer} />
      </Container>
    </div>
  );
}

export default App;


